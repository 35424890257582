import React from 'react';
import logo from './logo.svg';
import Main from './components/Main'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import './App.scss';


function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <Navbar></Navbar>
      <Main></Main>
      <Footer></Footer>
    </div>
  );
}

export default App;
