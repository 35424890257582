import React, { Component } from 'react';
import {
  Layout, Menu, Badge, Avatar, Button
} from 'antd';
import 'antd/dist/antd.css';
import { Row, Col, Typography, Divider } from 'antd';
import Logo from '../assets/vspk-logo.png';

const { Title, Paragraph, Text } = Typography;
const { Header } = Layout;
export default class Navbar extends Component {
  context
  state

  constructor(props) {
    super(props);
    this.context = this.props.context;
    this.state = {
    };
  }

  componentDidMount() {
  }
  render() {

    return (
        <div style={{ width: '100%',backgroundColor: 'white' }}>
        <div className="logo" style={{padding: 10}}>
          <img src={Logo} style={{objectFit: 'contain', maxHeight: 70}}/>
          <Title style={{fontFamily: ""}}>Villa Kitchen</Title>
        </div>
        </div>
    );
  }
}
// Navbar.contextType = CartContext
