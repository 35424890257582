import React, { Component } from 'react';
import { Row, Col, Typography, Divider } from 'antd';
import Grid from 'antd/lib/card/Grid';
import Banner from '../assets/villakitchen.jpg';


const { Title, Paragraph, Text } = Typography;

export default class Main extends Component {
  props
  menu = [
    {
      nameTH: 'ไก่กรอบพะแนง',
      name: 'Chicken Cutlet with Panang Sauce',
      description: 'Deep fried Chicken Cultlet With a side of Panag Curry',
      price: 65,
      imageUrl: require('../assets/food/panang-chicken.jpg'),
    },
    {
      nameTH: 'ไก่กรอบแกงกะหรี่',
      name: 'Chicken Cutlet with Thai Yellow Curry',
      description: 'Deep fried Chicken Cultlet With a side of Panag Curry',
      price: 75,
      imageUrl: require('../assets/food/thai-yellow-curry-chicken.jpg'),
    },
    {
      nameTH: 'ไก่กรอบซอสกะเพรา',
      name: 'Chicken Cutlet with Basil Sauce',
      description: 'Deep fried Chicken Cultlet With a side of Panag Curry',
      price: 65,
      imageUrl: require('../assets/food/basil-chicken.jpg'),
    },
    {
      nameTH: 'คะน้าหมูทอด',
      name: 'Fried Pork Cutlet with Chinese Broccoli',
      description: '',
      price: 65,
      imageUrl: require('../assets/food/stir-fry-kale-pork.jpg'),
    },

    {
      nameTH: 'กะเพราเนื้อตุ๋น',
      name: 'Basil Beef',
      description: 'great curry',
      price: 95,
      imageUrl: require('../assets/food/basil-beef.jpg'),
    },

    {
      nameTH: 'ข้าวหน้าไก่',
      name: 'Chicken Rice',
      description: '',
      price: 65,
      imageUrl: require('../assets/food/chicken-rice.jpg'),
    },
    
    // {
    // nameTH: 'ข้าวมันไก่',
    //   name: 'Chicken Browth Rice',
    //   description: '',
    //   price: 65,
    //   imageUrl: require('../assets/food/chicken-browth-rice.jpg'),
    // },
    {
      nameTH: 'โกยซีหมี่ไก่',
      name: 'Chicken Noodles with Gravy',
      description: '',
      price: 65,
      imageUrl: require('../assets/food/noodle-chicken.jpg'),
    },
    {
      nameTH: 'ก๋วยเตี๋ยวคั่วไก่',
      name: 'Koa Khai',
      description: '',
      price: 65,
      imageUrl: require('../assets/food/koa-kai.jpg'),
    },

    // {
    //   nameTH: 'ข้าวซอยไก่',
    //   name: 'Khao Soi Chicken',
    //   description: '',
    //   price: 65,
    //   imageUrl: require('../assets/food/khao-soi-chicken.jpg'),
    // },
    {
      // not sure if can do water suki?
      nameTH: 'สุกี้แห้ง/น้ำ (ไก่/หมู)',
      // i think sukiyaki is misleading especially for japs should be thai suki or something
      name: 'Sukiyaki (Pork/Chicken)',
      description: '',
      price: 65,
      imageUrl: require('../assets/food/sukiyaki.jpg'),
    },

  ]
  constructor(props){
    super(props)
  }
  render(){
    return (
      <>
      <div style={{height: '100%',}}>
        <div style={{ 
          paddingBottom: '40%',
          width: '100%', 
          textAlign: 'center',  
          position: 'relative', 
          background: '#ffe8df', 
          backgroundImage: "url("+Banner+")", 
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
          <div style={{ position: 'absolute',bottom:0, left:0, right:0,
              }}>
            <div style={{position: 'relative', width: '100%',overflow:'hidden'}}>
              <div style={{
                paddingTop: 70,
                paddingBottom: 40,  
                }}>
              <Row gutter={[0, 0]}  style={{margin: 20, maxWidth: '750px',margin: 'auto', textAlign: 'center'}}>
                <Col xs={{ span: 24 }}>
                 <div> <a style={{color: 'white', fontSize: 18,background: '#E90165', padding: 15, display: 'block', borderRadius: '50px', margin: 20,display: 'inline-block', fontSize: 18, textDecoration: 'none', color: 'white'}} target="_blank"  href="https://www.foodpanda.co.th/th/restaurant/p9le/villa-kitchen">Order On Foodpanda</a></div>
                </Col>
              </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
          <div style={{maxWidth: '1200px', margin: 'auto', marginTop: 100}}>
            <div style={{background: 'white'}}>
                <Title level={2}>
                  Online Menu 
                </Title>
                <Text style={{padding: 15, margin: 20, fontSize: 18}}>Open: 10:00 - 14:00</Text>
                <hr style={{width: 50, height: 5, backgroundColor: 'black'}}/>
                <Paragraph>พิเศษ! ส่วนลด <strong style={{fontSize: 18}}>10</strong> บาท ทุกเมนูเมื่อสั่งโดยตรงกับร้าน</Paragraph>
                <Paragraph>Order directly to get a ฿10 discount per item</Paragraph>

                <div style={{padding: 20}}>
                    { this.menu && this.menu.map((item) => 
                  <Grid style={{width: '100%', textAlign: 'start'}}>

                          <Row gutter={[20, 20]} style={{justifyContent: 'center', alignItems: 'center'}}>
                            <Col >
                              <img style={{width: 300}} src={item.imageUrl}/>
                            </Col>
                            <Col xs={12}>
                            <Title level={3}>{item.nameTH}</Title>
                            <Paragraph>{item.name}</Paragraph>
                            {/* <Paragraph>{item.description}</Paragraph> */}
                            </Col>
                            <Col xs={4}>
                                <div style={{fontSize: 35}} >{item.price -10}</div>
                                <div style={{fontSize: 16, color: 'red', textDecoration: 'line-through'}} >{item.price}</div>

                            </Col>
                          </Row>
                  </Grid>

                      )}
                  </div>

          </div>

        </div>
      </>
    );
    
  }
}
