import React, { Component } from 'react';
import {
  Layout,  Typography, Menu, Button
} from 'antd';
import 'antd/dist/antd.css';
const { Title, Paragraph, Text } = Typography;

const { Header } = Layout;
export default class Footer extends Component {
  context
  state

  constructor(props) {
    super(props);
    this.context = this.props.context;
    this.state = {
    };
  }

  componentDidMount() {
  }
  render() {

    return (
      <>
      <div style={{ display: 'inline-block', backgroundColor: '#f0f0f0', padding: 50, width: '100%',  margin: 'auto', marginTop: 40 }}>
        <Title level={2}>สั่งตรงกับเรา</Title>
        <Title level={2}>เบอร์: 052-084-850</Title>
        <Text>หรืิอ</Text>
        <Title level={3}>สั่งบนระบบ Foodpanda</Title>
        <Title level={4}>Search: Villa Kitchen ครัววิลล่า</Title>
        <a style={{color: 'white', background: '#E90165', padding: 20, display: 'block', borderRadius: '50px', margin: 20,display: 'inline-block', fontSize: 18, textDecoration: 'none', color: 'white'}} target="_blank"  href="https://www.foodpanda.co.th/th/restaurant/p9le/villa-kitchen">Order On Foodpanda</a>

      </div> 
      <div style={{margin: 'auto', fontSize: 18, backgroundColor: '#0f0f0f', padding: '20px', justifyContent: 'center'}}>
      <div style={{color: 'white'}}>Located At: <a  target="_blank" href="https://villasanpakoi.com">villasanpakoi.com</a></div>
      
      <div style={{color: 'white'}}>Made By: <a style={{color: 'orange'}} target="_blank"  href="https://brightdolphins.com">brightdolphins.com</a></div>

      </div>
    </>
    );
  }
}
// Navbar.contextType = CartContext
